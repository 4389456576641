import { Button } from '@material-tailwind/react'
import React from 'react'
import lm2021 from '../assets/lm2021.jpg'
import { useNavigate } from 'react-router-dom'

const Section3 = () => {

  const navigate = useNavigate()

  return (
    <div className='bg-[#f8f8f8] mt-5'>
      <div className='container mx-auto flex flex-col-reverse md:flex-row '>
        <div className='md:w-3/5 p-10 flex flex-col gap-3'>
          <p className='font-bold text-lg'>Quem somos</p>
          <p className='text-gray-600'>Uma desenvolvedora de sistemas para a gestão de empresas, fundada em 1996, seguimos entregando soluções aos nossos clientes, do comércio a indústria, do pequeno ao grande negócio, nos mais variados ramos de atividade.</p>
          <p className='text-gray-600'>Acreditamos que a informação é o princípio de uma gestão eficiente e que os nossos sistemas serão uma ferramenta de auxílio no dia-a-dia das organizações e dos profissionais.</p>
          <p className='text-gray-600'>Em prol de uma experiência positiva, nos orgulhamos de cada progresso obtido pelos nossos clientes, pois sabemos que o atendimento é um diferencial positivo e que o bom e velho relacionamento humano é a base de uma parceria duradoura.</p>
          <p className='text-gray-600'>Estamos aqui, contem conosco!</p>
          <Button onClick={() => navigate('/trabalhe-conosco')} color="blue">TRABALHE CONOSCO</Button>
        </div>
        <div className='md:w-2/5 mt-10'>
          <img src={lm2021} alt='lm' className='md:mb-0 mt-4 md:mt-0 rounded-lg' />
        </div>
      </div>

    </div>
  )
}

export default Section3